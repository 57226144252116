import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./components/App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@mui/material";
import { theme } from "./components/theme/Theme";
import { QueryClient, QueryClientProvider } from "react-query";
import { LocalizationHelper, Providers } from "@microsoft/mgt-element";
import { Msal2Provider } from "@microsoft/mgt-msal2-provider";
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
Providers.globalProvider = new Msal2Provider({
  clientId: CLIENT_ID,
  scopes: [
    "user.read",
    "openid",
    "profile",
    "people.read",
    "user.readbasic.all",
  ],
});

LocalizationHelper.strings = {
  _components: {
    login: {
      signInLinkSubtitle: "Iniciar sesión",
      signOutLinkSubtitle: "Salir",
    },
  },
};

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
