import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import FolderIcon from "@mui/icons-material/Folder";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import { useQuery } from "react-query";
import { createFolder, deleteElement } from "../../api/api";
import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  InputLabel,
  FormControl,
  Input,
  InputAdornment,
} from "@mui/material";
import { Help } from "./Help";
import { AccountCircle, Search } from "@mui/icons-material";

export const DirView = ({ allDir, dir, changeFolder, downloadFile }) => {
  const [folderInfo, setFolderInfo] = useState({});
  const [searchString, setSearchString] = useState("");
  const [fileInfo, setFileInfo] = useState({});
  const [open, setOpen] = useState(false);
  const [openActions, setOpenActions] = useState(false);

  const { refetch: sendFolder } = useQuery(
    ["createFolder"],
    () => {
      return createFolder(folderInfo);
    },
    {
      enabled: false,
    }
  );
  const { refetch: deleteE } = useQuery(
    ["deleteElement"],
    () => {
      return deleteElement(fileInfo);
    },
    {
      enabled: false,
    }
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleActionsOpen = () => {
    setOpenActions(true);
  };

  const handleActionClose = () => {
    setOpenActions(false);
  };
  const findFiles = async (search, d, newDir) => {
    for (let index = 0; index < d.length; index++) {
      if (d[index].children) {
        findFiles(search, d[index].children, newDir);
      } else {
        if (
          d[index].route
            .split("/")
            .at(-1)
            .toLowerCase()
            .includes(search.toLowerCase())
        ) {
          newDir.children.push(d[index]);
        }
      }
    }
  };
  useEffect(() => {
    if (allDir) {
      if (searchString !== "") {
        let nD = {
          name: `Busqueda`,
          children: [],
          route: `Busqueda`,
        };
        findFiles(searchString, allDir.children, nD);
        changeFolder(nD);
      } else {
        changeFolder(allDir);
      }
    }
  }, [searchString]);

  const NewFolder = ({ route }) => {
    return (
      <Grid item xs={3} md={2} onClick={handleClickOpen}>
        <Grid item xs={12} textAlign="center">
          <CreateNewFolderIcon sx={{ width: "50%", height: "50%" }} />
        </Grid>
        <Grid item xs={12} textAlign="center">
          Agregar nueva carpeta
        </Grid>
      </Grid>
    );
  };
  const Folder = ({ name, route, dir }) => {
    return (
      <Grid
        item
        xs={3}
        md={2}
        onClick={() => {
          changeFolder(dir);
        }}
      >
        <Grid item xs={12} textAlign="center">
          <FolderIcon sx={{ width: "50%", height: "50%" }} />
        </Grid>
        <Grid item xs={12} textAlign="center">
          {name}
        </Grid>
      </Grid>
    );
  };
  const File = ({ name, route, file }) => {
    return (
      <Grid
        item
        xs={3}
        md={2}
        onClick={async () => {
          setFileInfo(file);
          setOpenActions(true);
        }}
      >
        <Grid item xs={12} textAlign="center">
          <InsertDriveFileOutlinedIcon sx={{ width: "50%", height: "50%" }} />
        </Grid>
        <Grid item xs={12} textAlign="center">
          {name}
        </Grid>
      </Grid>
    );
  };
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      color="white"
    >
      <Grid item xs={10}>
        <FormControl variant="standard" focused fullWidth>
          <InputLabel htmlFor="SearchField">Buscar archivo</InputLabel>
          <Input
            id="SearchField"
            sx={{ color: "#ffff" }}
            value={searchString}
            startAdornment={
              <InputAdornment position="start">
                <Search color="primary" />
              </InputAdornment>
            }
            onChange={(e) => {
              setSearchString(e.target.value);
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} marginTop="10px">
        {dir.route}
      </Grid>

      <NewFolder />
      {dir.children
        .filter((d) => d.children)
        .map((d) => {
          return <Folder name={d.name} route={d.route} dir={d} key={d.route} />;
        })}
      {dir.children
        .filter((d) => !d.children)
        .map((d) => {
          return <File name={d.name} route={d.route} file={d} key={d.route} />;
        })}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Nueva Carpeta</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Nombre de la carpeta"
            type="email"
            fullWidth
            variant="standard"
            onChange={(e) => {
              setFolderInfo({
                nombre: e.target.value,
                ruta: dir.route,
              });
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              sendFolder();
              handleClose();
            }}
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openActions} onClose={handleActionClose}>
        <DialogTitle>¿Qué desea hacer?</DialogTitle>

        <DialogActions>
          <Button
            variant="primary"
            onClick={() => {
              downloadFile(fileInfo);
              handleActionClose();
            }}
          >
            Descargar
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              deleteE();
              handleActionClose();
            }}
          >
            Eliminar
          </Button>
          <Button variant="primary" onClick={handleActionClose}>
            Salir
          </Button>
        </DialogActions>
      </Dialog>
      <Help />
    </Grid>
  );
};
