import {
  AppBar,
  Modal,
  IconButton,
  Toolbar,
  Typography,
  Box,
} from "@mui/material";
import { Close } from "@material-ui/icons";
import { useAtom } from "jotai";
import { chatAtom } from "../globales/Atoms";
export const ChatBot = () => {
  const [open, setOpen] = useAtom(chatAtom);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="ChatBot"
      aria-describedby="Chatbot modal"
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: "4px",
          height: "100%",
          width: "400px",
          position: "fixed",
          bottom: "0",
          right: "0",
          "@media (max-width: 480px)": {
            width: "100%",
            height: "100%",
            borderRadius: "0px",
          },
        }}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <Close />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              ChatBot
            </Typography>
          </Toolbar>
        </AppBar>
        <iframe
          title="iframChatbot"
          src="https://webchat.botframework.com/embed/BotCSJAna?s=lxAXRBiOgRo.Vbp-_24OlGpCe_VD9bGkb9S-vQjOHH9WvHSdfUTfRzk"
          style={{ width: "100%", height: "90%", border: "none" }}
        ></iframe>
        {/* <iframe
        title="iframChatbot"
        src="https://webchat.botframework.com/embed/CSJAnabot?s=AcFn0KBbnd0.JECR77_rszoIDuK1R5vF_us05HVuHeVVz0InP54K2Qo"
        style={{ width: "100%", height: "100%" }}
      ></iframe> */}
      </Box>
    </Modal>
  );
};
