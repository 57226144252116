import { Box, Button } from "@mui/material";
import { Container } from "@mui/system";
import Avanti from "../../assets/images/IvantiLogo.svg";

export const MServicios = () => {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <Button>
        <img src={Avanti} alt={"Avanti"} />
      </Button>
    </Container>
  );
};
