import {
  Typography,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
} from "@mui/material";
import ImagenFondo from "../../assets/images/Soporte.jpg";
import ContactoChatBot from "../../assets/images/Icono_Chatbot.svg";
import ContactoLlamanos from "../../assets/images/Icono_Llamanos.svg";

import QRWhatsapp from "../../assets/images/QRWP.png";
import styled from "styled-components";
import { useAtom } from "jotai";
import { chatAtom } from "../globales/Atoms";
import { useEffect, useState } from "react";

const Container = styled.div`
  background-image: url(${ImagenFondo});
  background-size: cover;
  width: 100%;
  height: auto;
  @media (min-width: 1024px) {
  }
`;
const BoxB = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  color: white;
  border: 1px;
  align-items: center;
  justify-content: center;
  border-style: none none none none;
  font-size:2em;
  text-align: center;
  font-weight:bold;
  margin-bottom: 10px;
  
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  @media (min-width: 0) and (max-width: 915px) {    
     font-size:1em;
    padding-bottom:20px;
    padding-top:20px;
       border-style: none none solid none;
    flex-direction: column-reverse;

  }

`;

const BoxD = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  color: white;
  border: 1px;
  align-items: center;
  justify-content: center;
  font-size:2em;
  text-align: center;
  font-weight:bold;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  @media (min-width: 0) and (max-width: 915px) {
    font-size:1.5em;
    padding-bottom:20px;
    padding-top:20px;    
  }
  @media (min-width: 1921px){
    font-size:2.5em;
  }
`;

const Img = styled.img`
 width: auto;
  height: 150px;
  display: flex;
  margin: auto;
  margin-right: 3%;
  margin-left: 3%;
  justify-content: center;
  @media (min-width: 0) and (max-width: 915px) {
      margin-top:10px;
      width:100px;  
  }
  @media (min-width: 1921px){
    height: 200px;
  }
`;
const ImgQR = styled.img`
  width: 200px;
  display: flex;
  margin-right: 3%;
  margin-left: 3%;
  padding-top: 3%;
  padding-bottom: 3%;
  justify-content: center;
  @media (min-width: 0) and (max-width: 915px) {
      margin-top:10px;
      width: 200px;  
  }
  @media (min-width: 1921px){
    width: 400px;
  }
`;
export const Soporte = () => {
  const [open, setOpen] = useAtom(chatAtom);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });
  return (
    <Container
      style={{
        height:
          windowSize[0] < 650
            ? windowSize[1] - (56 + 60)
            : windowSize[1] - (64 + 80),
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="space-evenly"
        alignItems="flex-end"
        spacing={2}
        sx={{
          "@media (min-width: 1291px)": {
            height: "100%",
          },
          "@media (min-width: 1367px)": {
            height: "inherit", // Establecer altura en inherit para pantallas más grandes que 1280px de ancho
          },
        }}
      >
        <Grid item xs={5} md={5}>
          <BoxD
            onClick={() => {
              window.open("https://wa.me/3058308435");
            }}
          >
            <ImgQR src={QRWhatsapp} />
            Contacta por medio de Whatsapp:
            <br />
            305 8308435
          </BoxD>
        </Grid>
        <Grid item xs={5} md={5}>
          <BoxD onClick={() => setOpen(true)}>
            <Img src={ContactoChatBot} />
            Accede a nuestro chatbot
          </BoxD>
        </Grid>
        {/* <Grid item xs={5}>
          <BoxB>
            <ImgQR src={QRWhatsapp} />
            Contacta por medio de Whatsapp:
            <br />
            305 8308435
          </BoxB>
        </Grid>

        <Grid item xs={5}>
          <BoxD onClick={() => setOpen(true)}>
            <Img src={ContactoChatBot} />
            Accede a nuestro chatbot
          </BoxD>
        </Grid> */}
        {/* <Grid item xs={12} md={6}>
          <MyCard
            image={ContactoCorreo}
            action={() => {
              window.location.href =
                "mailto:correo@electronico.com?subject=subjet&body=A%20simple%20email%20message";
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MyCard
            image={ContactoWhatsapp}
            action={() => {
              window.open(
                "https://api.whatsapp.com/send?phone=573153577263&text=Mensaje",
                "_blank" // <- This is what makes it open in a new window.
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MyCard
            image={ContactoLlamada}
            action={() => {
              window.open(
                "tel:+573153577263",
                "_blank" // <- This is what makes it open in a new window.
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MyCard
            image={ContactoChatBot}
            action={() =>
              waLaunchChatWindow(
                "https://csjcc.ngncloudcomm.net/ChatSDK/",
                "Test",
                "name",
                "john.doe@company.com",
                "en-US",
                "properties"
              )
            }
          />
        </Grid> */}
      </Grid>
    </Container>
  );
};
