import styled from "styled-components";
import LogoETB from "../../assets/images/LOGO_BLANCO.png";
import LogoUTC from "../../assets/images/LOGO2.png";
import LogoYeapGo from "../../assets/images/YeapGo.svg";

const Img = styled.img`
  margin-left: 5px;
  height: 90%;
`;
const ImgUTC = styled.img`
  height: 90%;
  filter: invert(100%);
`;
const ImgYeapGo = styled.img`
  height: 60%;
  margin-left: auto;
  margin-right:5px;
`;
const Divider = styled.div`
  background: white;
  margin: 0px 10px 0px 10px;
  width: 1px;
  height: 70%;
`;

const BoxFooter = styled.div`
  color: white;
  position: fixed;
  width: 100%;
  bottom: 0px;
  height: 80px;
  display: flex;
  background: #af0e5b;
  flex-direction: row;
  align-items: center;
  @media (min-width: 0) and (max-width: 768px) {
    height: 60px;
  }
`;

export const Footer = () => {
  return (
    <BoxFooter>
      <Img src={LogoETB} alt="ETB logo" />
      <Divider />
      <ImgUTC src={LogoUTC} alt="UTC logo" />
      <ImgYeapGo src={LogoYeapGo} alt="YeapGo logo" />
    </BoxFooter>
  );
};
