const URL = process.env.REACT_APP_API_URL;
export const getDir = async () => {
  const response = await fetch(URL + "directorio");
  return response.json();
};

export const getFile = async (route) => {
  const response = await fetch(URL + `directorio/archivo?route=${route}`);

  return response.ok ? response.blob() : null;
};

export const sendFiles = async (files) => {
  const response = await fetch(URL + `directorio/`, {
    method: "POST",
    body: files,
  });

  return response.json();
};

export const createFolder = async (info) => {
  const response = await fetch(URL + `directorio/folder`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(info),
  });

  return response.json();
};

export const deleteElement = async (info) => {
  const response = await fetch(URL + `directorio/`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(info),
  });

  return response.json();
};
