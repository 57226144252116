import React, { useEffect } from "react";
import styled from "styled-components";
import Logo from "../../assets/images/csj.png";
import Logo3 from "../../assets/images/Login.jpg";
import { ReactComponent as CallUs } from "../../assets/images/Icono_Llamanos.svg";
import { Fab } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { Login as Login365 } from "@microsoft/mgt-react";
import { userAtom } from "../globales/Atoms";

const Container = styled.div`
  display: flex;
  height: 100vh;
`;
const ContentLeft = styled.div`
  width: 40%;
  background: #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;  
@media (min-width: 0) and (max-width: 768px) {
    width: 0%;
  }


`;
const ImgLogo = styled.img`
  width: 55%;
`;

const ContentRight = styled.div`
  width: 60%;
  background: white;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  @media (min-width: 0) and (max-width: 768px) {
    width:100%;
  }
`;

const SubContentRigth = styled.div`
  width: 100%;
  height: 94%;
`;
const ContentForm = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8em 0 0 6em;
  width: 40%; //Define el ancho del formulario
  @media (min-width: 0) and (max-width: 768px) {
    width:60%;
  }
`;
const TextWelcome = styled.p`
  font-size: calc(1vw + 0.9em);
  color: #fffc;
  font-weight: bold;
  margin: 2em 0 0.3em 0;
  cursor: default;
  @media (min-width: 300px) and (max-width: 425px) {
    font-size: calc(1vh + 0.7em);
  }
`;

const SpanAmarillo = styled.span`
  color: #fffc;
  font-weight: bold;
`;
const SpanAzul = styled.span`
  color: ##fffc;
  font-weight: bold;
`;
const SpanRojo = styled.span`
  color: #fffc;
  font-weight: bold;
`;
const TextInfo = styled.p`
  font-size: calc(1vw + 0.1em);
  color: #fffc;
  margin: 0 0 1em 0;
  cursor: default;
    @media (min-width: 0) and (max-width: 768px) {
     font-size: calc(1vh + 0.8em);

  }
`;
const BoxInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 1em 0;
`;
const Label = styled.label`
  color: #fffc;
  margin: 0.3em 0;
  @media (min-width: 300px) and (max-width: 425px) {
    font-size: calc(0.5vh + 0.7em);
  }
`;
const BoxField = styled.div`
  //width: 100%;
  border: 1px solid #666666;
  border-radius: 0.25rem;
  box-sizing: border-box;
  display: flex;
  padding-left: 0.2em;
  :hover {
    border: 1px solid #1a1a1a;
  }
`;
const Input = styled.input`
  font-size: calc(1vw + 0.4em);
  padding: 0.5em 0.5em 0.5em 0.2em;
  background: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  color: #fffc;
  width: 90%;
  @media (min-width: 300px) and (max-width: 425px) {
    font-size: calc(1vh + 0.8em);
  }
`;
const BoxButtons = styled.div`
  justify-content: center;
  display: flex;
  width: 100%;
`;

export default function Login() {
  const [uAtom, setUAtom] = useAtom(userAtom);
  const navigate = useNavigate();
  useEffect(() => {
    if (uAtom.nickname) {
      navigate("/home");
    }
  }, [uAtom]);
  return (
    <Container>
      <ContentLeft sx={0}>
        <ImgLogo src={Logo} />
      </ContentLeft>

      <ContentRight
        style={{
          backgroundImage: `url(${Logo3})`,
          backgroundSize: "cover",
          color: "#f5f5f5",
        }}
      >
        <SubContentRigth>
          <ContentForm>
            <TextWelcome>
              Bienvenido al Portal Web del
              <SpanAmarillo> C</SpanAmarillo>
              <SpanAzul> S</SpanAzul>
              <SpanRojo> J!</SpanRojo>
            </TextWelcome>
            <TextInfo>Ingresa con tu cuenta de Microsoft 365</TextInfo>

            {/* <form style={{ width: "100%" }}>
              <BoxInput>
                <Label>USUARIO</Label>
                <BoxField>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="6%"
                    fill="#fffc"
                  >
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v1c0 .55.45 1 1 1h14c.55 0 1-.45 1-1v-1c0-2.66-5.33-4-8-4z" />
                  </svg>
                  <Input type="text" name="user" />
                </BoxField>
              </BoxInput>

              <BoxInput>
                <Label>CONTRASEÑA</Label>
                <BoxField>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="6%"
                    fill="#fffc"
                  >
                    <g fill="none">
                      <path d="M0 0h24v24H0V0z" />
                      <path d="M0 0h24v24H0V0z" opacity=".87" />
                    </g>
                    <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zM9 8V6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9z" />
                  </svg>
                  <Input type="password" name="password" />
                </BoxField>
              </BoxInput>

              
            </form> */}
            <BoxButtons>
              {/* <Button
                width={"100%"}
                type="submit"
                name={"INGRESAR"}
                color={"#000109"}
                colorBorder={"#1C1C1C"}
                bg={"#fffc"}
                padding={"0.6em 1.5em"}
                fontSize={"calc(1vw + 0.2em)"}
                bgHover={"#fffa"}
                onClick={() => {}}
              /> */}
              <Login365 style={`background-color: #fffc;border-radius: 5px;`} />
            </BoxButtons>
          </ContentForm>
          <Fab
            onClick={() => {
              navigate("/CanalesDeContacto");
            }}
            sx={{}}
            fontSize="large"
            style={{
              padding: "10px 10px",
              margin: 0,
              top: "auto",
              right: 100,
              bottom: 50,
              left: "auto",
              position: "fixed",
              background: "transparent",
            }}
            aria-label="call us"
          >
            <CallUs />
          </Fab>
        </SubContentRigth>
      </ContentRight>
    </Container>
  );
}
