import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  TextField,
  Button,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const Help = () => {
  const navigate = useNavigate();
  const [text, setText] = useState("");
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"md"}>
        <DialogTitle>Informa sobre documentación faltante</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            multiline
            rows={4}
            margin="dense"
            label=""
            fullWidth
            color="secondary"
            onChange={(e) => {
              setText(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              handleClose();
            }}
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      <Fab
        size="medium"
        variant="extended"
        color="secondary"
        onClick={() => navigate("/home/soporteenlinea")}
        style={{
          margin: 0,
          top: "auto",
          right: 20,
          bottom: 90,
          left: "auto",
          position: "fixed",
        }}
      >
        ¿No encuentras lo que buscas?
      </Fab>
    </>
  );
};
