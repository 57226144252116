import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "./Dashboard";
import Login from "./login/Login";
import { CanalesContacto } from "./principal/CanalesContacto";
import { Footer } from "./principal/Footer";
import NavBar from "./principal/NavBar";
import { Providers, ProviderState } from "@microsoft/mgt-element";
import { useAtom } from "jotai";
import { userAtom } from "./globales/Atoms";
import { ChatBot } from "./chat/ChatBot";

export function useIsSignedIn() {
  const [isSignedIn, setIsSignedIn] = useState(false);

  useEffect(() => {
    const updateState = () => {
      const provider = Providers.globalProvider;
      setIsSignedIn(provider && provider.state === ProviderState.SignedIn);
    };
    Providers.onProviderUpdated(updateState);
    updateState();

    return () => {
      Providers.removeProviderUpdatedListener(updateState);
    };
  }, []);

  return [isSignedIn];
}

function App() {
  const [uAtom, setUAtom] = useAtom(userAtom);
  const fillAtom = async () => {
    let provider = Providers.globalProvider;
    if (provider) {
      let graphClient = provider.graph.client;
      let userDetails = await graphClient.api("me").get();
      setUAtom({
        nickname: userDetails.displayName,
        name: userDetails.givenName,
        lastName: userDetails.surname,
        email: userDetails.mail,
      });
    }
  };
  const [isSignedIn] = useIsSignedIn();
  useEffect(() => {
    if (isSignedIn) {
      fillAtom();
    } else {
      setUAtom({});
    }
  }, [isSignedIn]);

  return (
    <Router>
      <Routes>
        <Route
          path="/CanalesDeContacto"
          element={
            <>
              <ChatBot />
              <NavBar />
              <CanalesContacto />
              <Footer />
            </>
          }
        />
        <Route path="/" element={<Login />} />
        <Route path="/home/*" element={<Dashboard />} />
      </Routes>
    </Router>
  );
}

export default App;
