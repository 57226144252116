import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Img1 from "../../assets/images/Icono_Chat_Header.svg";
import Home from "../../assets/images/home.svg";
import Img2 from "../../assets/images/CSJ-01.png";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Login as Login365 } from "@microsoft/mgt-react";
import { useAtom } from "jotai";
import { chatAtom, userAtom } from "../globales/Atoms";

const Divider = styled.div`
  background: #000;
  width: 1px;
  height: 2.5rem;
  @media (max-width: 768px) {
    visibility: collapse;
  }
`;

const Img = styled.img`
  width: 80px;
  height: 20%;
  display: flex;
  justify-content: center;
`;
const BoxButton = styled.div`
  @media (max-width: 600px) {
    width:0;
    visibility: collapse;
  }
`;

export default function NavBar() {
  const [uAtom, setUAtom] = useAtom(userAtom);
  const logoutAtom = () => {};
  const navigate = useNavigate();

  const [windowSize, setWindowSize] = React.useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  React.useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const SpanColor = ({ text, color = "inherit" }) => {
    return (
      <Typography
        component="span"
        variant="h6"
        noWrap
        color={color}
        sx={{ fontWeight: "bold" }}
      >
        {text}
      </Typography>
    );
  };
  const BoxNavBar = styled.div``;
  const [open, setOpen] = useAtom(chatAtom);
  return (
    <BoxNavBar sx={{ backgroundColor: "#f2f2f2" }} id="boxnavbar">
      <AppBar position="static" sx={{ backgroundColor: "#f2f2f2" }}>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Img src={Img2} alt="CSJ" style={{ height: "3rem", width: "auto" }} />
          {windowSize[0] > 768 ? (
            <Typography variant="h6" component="div">
              <u>
                <SpanColor text={"Portal Web de CSJ"} color={"#000000"} />
              </u>
            </Typography>
          ) : (
            <></>
          )}

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <BoxButton>
              <Button>
                <Img
                  onClick={() => {
                    navigate("/home");
                  }}
                  src={Home}
                  alt="Home"
                  style={{ height: "2rem", width:"auto" }}
                />
              </Button>
            </BoxButton>
            <BoxButton>
              <Button>
                <Img
                  onClick={() => {
                    setOpen(true);
                  }}
                  src={Img1}
                  alt="ChatBot"
                  style={{ height: "2rem", width:"auto" }}
                />
              </Button>
            </BoxButton>
            <Divider />
            <Login365 />
          </Box>
        </Toolbar>
      </AppBar>
      <br />
    </BoxNavBar>
  );
}
