import {
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import imgCanalesDeContacto from "../../assets/images/Canales.jpg";
import imgGestionDocumental from "../../assets/images/gestion.jpg";
import imgMesaDeServicios from "../../assets/images/Mesa.jpg";
import imgSoporteEnLinea from "../../assets/images/Soporte.jpg";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useAtom } from "jotai";
import { chatAtom } from "../globales/Atoms";
import { useEffect, useState } from "react";
const Box = styled.div`
  max-width: 60%;
  padding-top:10px;
  margin-bottom:10px;

  @media (max-width: 1280px) {   
    max-width: 80%;
  }
  @media (min-width: 1280px) {
    max-width: 90%; 
  }
@media (min-height: 780px) {
    max-width: 90%; 
    padding-top:60px;
  }

@media (min-height: 0) and (max-height: 650px) {
    margin-bottom:80px;
  }
  @media (min-width: 650px) and (max-width: 768px) {
    margin-bottom:80px;
  }@media (min-width: 0) and (max-width: 650px) {
    margin-bottom:60px;
  }
`;

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* dos columnas de tamaño igual */
  grid-gap: 20px; /* espacio entre las cards */
  height: 75vh; /* altura igual a la pantalla */
  width: 90vw; /* ancho igual a la pantalla */

  @media (max-width: 767px) {
    /* si la pantalla es menor a 767px de ancho */
    grid-template-columns: 1fr; /* solo una columna de tamaño igual */
  }
`;

export const Menu = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useAtom(chatAtom);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });
  const MyCard = ({ name, description, action, image }) => {
    return (
      <Card
        sx={{
          backgroundImage: `url(${image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          padding: "0 0 0 0",
          height: "100%" /* altura igual al contenedor de las tarjetas */,
          width: "100%" /* ancho igual al contenedor de las tarjetas */,
        }}
        display="flex"
      >
        <CardActionArea
          onClick={action}
          sx={{
            height: "100%" /* altura igual al contenedor de las tarjetas */,
            width: "100%" /* ancho igual al contenedor de las tarjetas */,
          }}
        >
          <CardContent
            sx={{
              padding: "0",
              height: "100%" /* altura igual al contenedor de las tarjetas */,
              width: "100%" /* ancho igual al contenedor de las tarjetas */,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center", // Centrar verticalmente los elementos
              position: "relative", // Agregar posición relativa
            }}
          >
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              textAlign={"center"}
              color={"#fffc"}
              fontWeight={"bold"}
              fontSize={"2rem"}
              sx={{
                alignSelf: "center",
                fontSize: "2rem",
                "@media (max-width: 767px)": {
                  fontSize: "1.6rem",
                },
                "@media (min-width: 768px) and (max-width: 1023px)": {
                  fontSize: "1.8rem",
                },
                "@media (min-width: 1024px)": {
                  fontSize: "3rem",
                },
              }}
            >
              {name}
            </Typography>

            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                color: "#000",
                bgcolor: "#fffc",
                width: "100%",
                padding: "2%",
                position: "absolute", // Agregar posición absoluta
                bottom: "0", // Fijar en la parte inferior
                left: "0", // Fijar en la parte izquierda
              }}
            >
              {description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  };

  return (
    <CardGrid id="box">
      <MyCard
        name="Manuales y preguntas frecuentes"
        description="Encuentra la respuesta a tus preguntas"
        image={imgGestionDocumental}
        action={() => navigate("gestionDocumental")}
      />

      <MyCard
        name={`Mesa de servicios (Proximamente...)`}
        description="Mesa de servicios"
        image={imgMesaDeServicios}
      />

      <MyCard
        name="Soporte en linea"
        description="Pide ayuda"
        image={imgSoporteEnLinea}
        action={() => navigate("soporteenlinea")}
      />

      <MyCard
        name="Canales de contacto"
        description="Contacta con nosotros"
        image={imgCanalesDeContacto}
        action={() => navigate("canalesContacto")}
      />
    </CardGrid>
  );
};
