import { Grid, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import FolderTree, { testData } from "react-folder-tree";
import "react-folder-tree/dist/style.css";
import { useQuery } from "react-query";
import { getDir, getFile, sendFiles } from "../../api/api";
import { DirView } from "./DirView";

export const FileSystem = () => {
  const [dir, setDir] = useState({ name: "default", children: [] });
  const [file, setFile] = useState({});
  const [dataFiles, setDataFiles] = useState({});
  const { data } = useQuery(["dir"], getDir, {
    refetchInterval: 500,
  });
  const {
    data: dataQ,
    status,
    refetch,
  } = useQuery(
    [file.route],
    () => {
      return getFile(file.route);
    },
    {
      enabled: false,
    }
  );

  const { data: dataPost, refetch: sendF } = useQuery(
    ["uploadFiles"],
    () => {
      return sendFiles(dataFiles);
    },
    {
      enabled: false,
    }
  );

  const [filesToUpload, setFilesToUpload] = useState({
    name: "Archivos a subir",
    children: [],
  });
  useEffect(() => {
    if (data) {
      if (dir.name === "default") {
        setDir(data);
      } else {
        findRoute(dir.route, [data]);
      }
    }
  }, [data]);
  useEffect(() => {
    if (dataQ) {
      let a = document.createElement("a");
      a.href = window.URL.createObjectURL(dataQ);
      a.download = file.name;
      a.click();
    }
  }, [dataQ]);

  const downloadFile = async (f) => {
    setFile(f);
    setTimeout(() => {
      refetch();
    }, 100);
  };

  const changeFolder = (newDir) => {
    setDir(newDir);
  };
  const onClickTree = async ({ nodeData }) => {
    findRoute(nodeData.route, [data]);
  };

  const findRoute = async (route, d) => {
    for (let index = 0; index < d.length; index++) {
      if (d[index].route === route) {
        if (d[index].children) {
          changeFolder(d[index]);
        }
      }
      if (d[index].children) {
        findRoute(route, d[index].children);
      }
    }
  };
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      color="white"
    >
      <Grid item xs={4} md={3}>
        <FolderTree
          data={data ?? {}}
          showCheckbox={false}
          readOnly
          onNameClick={onClickTree}
        />
      </Grid>
      <Grid item xs={8} md={9}>
        <DirView
          allDir={data}
          dir={dir}
          changeFolder={changeFolder}
          downloadFile={downloadFile}
        />
        <Dropzone
          style={{ border: "2px dashed" }}
          onDrop={(acceptedFiles) => {
            let d = new FormData();
            for (let index = 0; index < acceptedFiles.length; index++) {
              d.append(
                "files",
                acceptedFiles[index],
                acceptedFiles[index].name
              );
            }
            d.append("path", dir.route);
            setDataFiles(d);
            setTimeout(() => {
              sendF();
            }, 100);
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div
                {...getRootProps()}
                style={{
                  border: "2px dashed",
                  height: "100px",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <input {...getInputProps()} />
                <p>Arrastra o selecciona los archivos que deseas subir</p>
              </div>
            </section>
          )}
        </Dropzone>
      </Grid>
      {/* <Grid item xs={12} md={6}>
        <Dropzone
          onDrop={(acceptedFiles) => {
            let list = { ...filesToUpload };
            for (let index = 0; index < acceptedFiles.length; index++) {
              list.children.push({
                name: acceptedFiles[index].path,
                _id: index,
              });
            }
            setFilesToUpload(list);
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <p>Arrastra o selecciona los archivos que deseas subir</p>
              </div>
            </section>
          )}
        </Dropzone>
        <FolderTree data={filesToUpload} />
      </Grid> */}
    </Grid>
  );
};
