import {
  Typography,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
} from "@mui/material";
import ImagenFondo from "../../assets/images/Mesa.jpg";
import ContactoChatBot from "../../assets/images/Icono_Chatbot.svg";
import ContactoLlamanos from "../../assets/images/Icono_Llamanos.svg";

import ContactoCorreo from "../../assets/images/Icono_Correo.svg";
import QRWhatsapp from "../../assets/images/QRWP.png";
import styled from "styled-components";
import { useAtom } from "jotai";
import { chatAtom } from "../globales/Atoms";
import { useEffect, useState } from "react";

const Container = styled.div`
  background-image: url(${ImagenFondo});
  background-size: cover;
  width: 100%;
  height: auto;
  @media (min-width: 1024px) {
  }
`;

const BoxC = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  color: white;
  border: 1px;
  align-items: center;
  justify-content: center;
  padding-top: 2%;
  padding-bottom: 2%;
  font-size:1.2em;
  text-align: center;
  font-weight:bold; 
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  @media (min-width: 0) and (max-width: 915px) {
     font-size:1em;
    padding-bottom:20px;
    padding-top:20px;    
    flex-direction: column-reverse;
  }

`;
const ImgQR = styled.img`
  width: auto;
  height: 100px;
  display: flex;
  margin: auto;
  margin-right: 3%;
  margin-left: 3%;
  justify-content: center;
  @media (min-width: 0) and (max-width: 915px) {
      margin-top:10px;
      width:100px;  
  }
  @media (min-width: 1921px){
    height: 200px;
  }
`;
export const CanalesContacto = () => {
  const [open, setOpen] = useAtom(chatAtom);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });
  return (
    <Container
      style={{
        height:
          windowSize[0] < 650
            ? windowSize[1] - (56 + 60)
            : windowSize[1] - (64 + 80),
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="space-evenly"
        alignItems="flex-end"
        spacing={2}
        sx={{
          "@media (min-width: 1367px)": {
            height: "inherit",
          },
          "@media (max-width: 915px)": {
            alignItems: "center",
          },
        }}
      >
        <Grid item xs={5} md={5}>
          <BoxC>
            <ImgQR src={ContactoCorreo} />
            <ul style={{ listStyle: "none" }}>
              <li>
                Correo mesa de servicios: mesadeayuda@deaj.ramajudicial.gov.co
              </li>
              <li>
                Soporte Correo y Office 365:
                soportecorreo@cendoj.ramajudicial.gov.co
              </li>
              <li>
                PQRSF - Mesa de Ayuda: pqrmesadeayuda@deaj.ramajudicial.gov.co
              </li>
            </ul>
          </BoxC>
        </Grid>
        <Grid item xs={5} md={5}>
          <BoxC>
            <ImgQR src={ContactoLlamanos} />
            <div style={{ paddingRight: "30px", paddingLeft: "30px" }}>
              <ul style={{ listStyle: "none" }}>
                <li>Linea Gratuita: 018000 124595</li>
                <li>Linea fija: 601 5556666</li>
                <li>Linea Celular: 305 8308411 | 305 8308435</li>
              </ul>
            </div>
          </BoxC>
        </Grid>
      </Grid>
    </Container>
  );
};
