import { Grid } from "@mui/material";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ChatBot } from "./chat/ChatBot";
import { userAtom } from "./globales/Atoms";
import { CanalesContacto } from "./principal/CanalesContacto";
import { FileSystem } from "./principal/FileSystem";
import { Footer } from "./principal/Footer";
import { Menu } from "./principal/Menu";
import { MServicios } from "./principal/MServicios";
import NavBar from "./principal/NavBar";
import { Soporte } from "./principal/Soporte";

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
const Dashboard = () => {
  const navigate = useNavigate();
  const [uAtom] = useAtom(userAtom);

  useEffect(() => {
    if (!uAtom.nickname) {
      navigate("/");
    }
  }, [uAtom]);
  return (
    <Grid container sx={{ background: "black" }}>
      <ChatBot />
      <Grid item xs={12} id="navbar-grid" sx={{ height: "64px" }}>
        <NavBar></NavBar>
      </Grid>
      <Container>
        <Routes>
          <Route path="/gestionDocumental" element={<FileSystem />}></Route>
          <Route path="/canalesContacto" element={<CanalesContacto />}></Route>
          <Route path="/mesaServicios" element={<MServicios />}></Route>
          <Route path="/soporteenlinea" element={<Soporte />}></Route>
          <Route path="/*" element={<Menu />}></Route>
        </Routes>
      </Container>
      <Footer />
    </Grid>
  );
};

export default Dashboard;
